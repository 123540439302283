@layer components {
	.h0 {
		@apply font-extrabold text-blue-dark leading-none text-[69px];
	}

	.h1,
	.prose h1 {
		@apply font-extrabold text-blue-dark text-[44px] leading-[48px] lg:text-[55px] lg:leading-[55px];
	}

	.h2,
	.prose h2 {
		@apply font-extrabold text-blue-dark text-[32px] leading-[36px] lg:text-[44px] lg:leading-[48px];
	}

	.h3,
	.prose h3 {
		@apply font-extrabold text-blue-dark text-[23px] leading-[28px] lg:text-[32px] lg:leading-[36px];
	}

	.h4,
	.prose h4 {
		@apply font-extrabold text-blue-dark text-[18px] leading-[24px] lg:text-[23px] lg:leading-[28px];
	}

	.h5,
	.h6,
	.prose h5,
	.prose h6 {
		@apply font-extrabold text-blue-dark text-[16px] leading-[24px] lg:text-[18px] lg:leading-[24px];
	}

	.prose img {
		@apply rounded;
	}

	.prose strong {
		@apply font-extrabold text-blue-dark;
	}

	.prose.prose-white {
		h1, h2, h3, h4, h5, h6, strong {
			@apply text-white;
		}
	}
}
