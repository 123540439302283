/* Custom utilities */

@layer utilities {
	.debug-screens::before {
		@apply rounded shadow-none text-white bg-red m-4 opacity-100 p-2;
	}

	.py-base {
		@apply py-5 sm:py-10;
	}

	.white-gradient {
		background-image: linear-gradient(
			to right,
			rgba(255, 255, 255, 1.0) 0%,
			rgba(255, 255, 255, 1.0) 70%,
			rgba(255, 255, 255, 0.8) 85%,
			rgba(255, 255, 255, 0.4) 100%
		);
	}
}
