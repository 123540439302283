/* Global print styles */

@media print {
	/* Hide all the header elements except the logo */
	header * { @apply !shadow-none; }
	header button { @apply hidden; }

	body > div[role="presentation"] > footer,
	[data-palette],
	.debug-screens::before,
	iframe {
		@apply hidden
	}

  img {
		@apply max-w-full
	}

	.container {
		@apply max-w-none
	}

	html {
		@apply text-[12px]
	}
}
