@import "gridjs/dist/theme/mermaid.css";

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './base.pcss';
@import './libraries.pcss';
@import './typography.pcss';
@import './components.pcss';
@import './utilities.pcss';
@import './print.pcss';
