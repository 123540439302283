@layer components {
	/* GridJS */
	.gridjs-th {
		@apply !bg-gray-light uppercase !text-blue-dark !font-regular;
	}

	.gridjs-td:first-of-type {
		@apply text-red;
	}
	.gridjs-td {
		@apply font-medium;
	}
}
