/* Components go here */

@layer components {
	.button {
		@apply bg-red text-white px-6 py-3 rounded-[48px] font-extrabold;
		@apply transition-opacity hover:opacity-80;
	}

	.button-inverse {
		@apply bg-white text-red px-6 py-3 rounded-[48px] font-extrabold;
		@apply transition-opacity hover:opacity-80;
	}

	.button-outline {
		@apply button bg-white text-red border-[3px] border-red;
	}
}
